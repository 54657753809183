import React from 'react';
import { FormikValues, useField } from 'formik';
import { onValidate } from './TextInput';

export interface IField {
  type?: string;
  label?: string;
  required?: boolean;
  disabled?: boolean;
  name: string;
  children?: JSX.Element;
  style?: React.CSSProperties | undefined;
  maxLength?: number;
  placeholder?: string;
  data?: { value: string | number; label: string }[];
}

export const InputFormik: React.FC<IField> = ({ maxLength, type, placeholder, label, name, required }) => {
  const [field, meta] = useField({
    name,
    validate: (value: FormikValues) => onValidate(value, { require: required, max: maxLength }),
  });
  return (
    <div>
      {label ? <label htmlFor={name}>{label}</label> : null}
      <input className="form-control" type={type} placeholder={placeholder} {...field} />
      {meta.error && meta.touched ? <span style={{ color: 'red' }}>{meta.error}</span> : null}
      <br />
    </div>
  );
};

export const SelectFormik: React.FC<IField> = ({ label, name, required, children, data }) => {
  const [field] = useField({
    name,
    validate: (value: FormikValues) => onValidate(value, { require: required }),
  });
  return (
    <div>
      {label ? <label htmlFor={name}>{label}</label> : null}
      <select className="form-control" {...field}>
        {data?.map((option) => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>
      <br />
    </div>
  );
};
