import React, { useReducer } from 'react';
import { usersState } from './state';
import { reducer } from './reducer';
import { actionUsers } from './actions';
import { Users } from '../../Request/users';
import { InitialUsersType, UpdateUserType } from '../../Types/UsersType';
import { ROUTES } from '../../utils/routes';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../auth/provider';
import { TypeAdmin } from '../../utils/config';

export const UsersContext = React.createContext<InitialUsersType>({} as InitialUsersType);

interface Props {
  children: any;
}

export const UsersProvider = ({ children }: Props) => {
  const [state, dispatch] = useReducer(reducer, usersState);
  const navigate = useNavigate();

  const { isAdmin } = React.useContext(AuthContext);

  React.useEffect(() => {
    if (isAdmin !== TypeAdmin.ADMIN) {
      navigate(ROUTES.amailers);
    }
  }, [isAdmin, navigate]);

  React.useEffect(() => {
    if (isAdmin === TypeAdmin.ADMIN) {
      Users.getUsers().then((data) => {
        dispatch(actionUsers.setUsers(data));
      });
    }
  }, [isAdmin]);

  const createUser = React.useCallback(
    async (user: UpdateUserType) => {
      try {
        if (state.disabled) return null;
        dispatch(actionUsers.setError(null));
        dispatch(actionUsers.setDisabled(true));
        const data = await Users.createUser({ ...user, isAdmin: Number(user.isAdmin) });
        dispatch(actionUsers.setUser(data));
        dispatch(actionUsers.setDisabled(false));
        navigate(ROUTES.users);
      } catch (e: any) {
        if (e.response && e.response.data) {
          dispatch(actionUsers.setError(e.response.data.message));
        }
        dispatch(actionUsers.setDisabled(false));
      }
    },
    [state.disabled, navigate]
  );

  const updateUser = React.useCallback(
    async (user: UpdateUserType, id: number) => {
      try {
        if (state.disabled) return null;
        dispatch(actionUsers.setError(null));
        dispatch(actionUsers.setDisabled(true));
        const data = await Users.updateUser(id, { ...user, isAdmin: Number(user.isAdmin) });
        dispatch(actionUsers.updateUser(data));
        dispatch(actionUsers.setDisabled(false));
        navigate(ROUTES.users);
      } catch (e: any) {
        console.log(e.response);
        if (e.response && e.response.data) {
          dispatch(actionUsers.setError(e.response.data.message));
        }
        dispatch(actionUsers.setDisabled(false));
      }
    },
    [state.disabled, navigate]
  );

  const deleteUser = React.useCallback(
    async (id: number) => {
      try {
        if (state.disabled) return null;
        dispatch(actionUsers.setError(null));
        dispatch(actionUsers.setDisabled(true));
        const data = await Users.deleteUser(id);
        dispatch(actionUsers.deleteUser(data));
        dispatch(actionUsers.setDisabled(false));
        navigate(ROUTES.users);
      } catch (e: any) {
        if (e.response && e.response.data) {
          dispatch(actionUsers.setError(e.response.data.message));
        }
        dispatch(actionUsers.setDisabled(false));
      }
    },
    [state.disabled, navigate]
  );

  const value = {
    ...state,
    createUser,
    updateUser,
    deleteUser,
  };

  return <UsersContext.Provider value={value}>{children}</UsersContext.Provider>;
};
