import { instance } from './istance';
import { AuthType, TokensType } from '../Types/AuthType';

export class Auth {
  static async postLogin(auth: AuthType) {
    return instance.post<TokensType>('api/auth/login', auth).then((res) => res.data);
  }

  static async refreshToken(refreshToken: string) {
    return instance.post<TokensType>('api/auth/refresh-tokens', { refreshToken }).then((res) => res.data);
  }
}
