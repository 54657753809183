export const ROUTES = {
  amailers: '/amailers',
  users: '/users',
  delete: '/delete',
  login: '/login',
  routeChange: function (type: string | number) {
    const path = window.location.pathname.split('/')[1];
    return '/' + path + '/' + type;
  },
};
