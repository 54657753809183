import React from 'react';
import './App.css';
import { BrowserRouter as Router, Route, Routes, useNavigate } from 'react-router-dom';
import { AmailersProvider } from './state/amailers/provider';
import { Amailers, AmailersEdit, CreateUser, DeleteUser, Login, UpdateUser, UserList } from './pages';
import { AuthProvider } from './state/auth/provider';
import { ROUTES } from './utils/routes';
import { UsersProvider } from './state/users/provider';

function Main() {
  const navigate = useNavigate();
  React.useEffect(() => {
    navigate(ROUTES.amailers);
  }, [navigate]);
  return null;
}

function App() {
  return (
    <Router>
      <AuthProvider>
        <div className="App">
          <Routes>
            <Route path={''} element={<Main />} />
            <Route path={ROUTES.login} element={<Login />} />

            <Route
              path={ROUTES.amailers}
              element={
                <AmailersProvider>
                  <Amailers />
                </AmailersProvider>
              }
            />
            <Route
              path={`${ROUTES.amailers}/:id`}
              element={
                <AmailersProvider>
                  <AmailersEdit />
                </AmailersProvider>
              }
            />
            <Route
              path={ROUTES.users}
              element={
                <UsersProvider>
                  <UserList />
                </UsersProvider>
              }
            />
            <Route
              path={`${ROUTES.users}/new`}
              element={
                <UsersProvider>
                  <CreateUser />
                </UsersProvider>
              }
            />
            <Route
              path={`${ROUTES.users}/:id`}
              element={
                <UsersProvider>
                  <UpdateUser />
                </UsersProvider>
              }
            />
            <Route
              path={`${ROUTES.delete}/:id`}
              element={
                <UsersProvider>
                  <DeleteUser />
                </UsersProvider>
              }
            />
          </Routes>
        </div>
      </AuthProvider>
    </Router>
  );
}

export default App;
