import { instance } from './istance';
import { AmailersType, ConfigurationType, UpdatedAmailersType } from '../Types/AmailersType';
import { TOKEN } from '../utils/config';

export class Amailers {
  static get token() {
    return localStorage.getItem(TOKEN.access);
  }

  static async getConfiguration() {
    return instance
      .get<ConfigurationType>(`api/configuration/admin`, {
        headers: {
          Authorization: this.token,
        },
      })
      .then((res) => res.data);
  }

  static async getAmailers(subdivision: string, channel: string) {
    return instance
      .get<AmailersType[]>(`api/mailing/admin/all-amailers?subdivision=${subdivision}&channel=${channel}`, {
        headers: {
          Authorization: this.token,
        },
      })
      .then((res) => res.data);
  }

  static async updateAmailers(id: string, data: UpdatedAmailersType) {
    return instance
      .post(`api/mailing/admin/edit-amailers/${id}`, data, {
        headers: {
          Authorization: this.token,
        },
      })
      .then((res) => res.data);
  }
}
