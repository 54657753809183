import React from 'react';
import { Form, Formik } from 'formik';
import { UpdateUserType } from '../../Types/UsersType';
import { TypeAdmin } from '../../utils/config';
import { InputFormik, SelectFormik } from '../ui';
import CustomSelect from '../ui/CustomSelect';

type PropsType = {
  data?: UpdateUserType;
  onSubmit: (data: UpdateUserType) => void;
};

export const UserFrom = ({ data, onSubmit }: PropsType) => {
  const [isPassword, setIsPassword] = React.useState(!data);
  const [selectedItems, setSelectedItems] = React.useState<number[]>([]);

  React.useEffect(() => {
    setIsPassword(!data);
    if (data?.cat_id) {
      setSelectedItems(data.cat_id);
    }
  }, [data]);

  return (
    <Formik
      enableReinitialize={true}
      initialValues={{
        fio: data?.fio || '',
        username: data?.username || '',
        password: '',
        isAdmin: data?.isAdmin || 0,
      }}
      onSubmit={(values) => onSubmit({ ...values, cat_id: selectedItems })}
    >
      {() => (
        <Form id="upgrade_info">
          <InputFormik type="email" label="Е-пошта" name="username" required />
          {isPassword ? (
            <InputFormik type="password" label="Пароль" name="password" required />
          ) : (
            <div style={{ marginBottom: '15px' }}>
              <button type="button" className="btn btn-default" onClick={() => setIsPassword(true)}>
                Змінити пароль
              </button>
            </div>
          )}
          <InputFormik label="ПІБ" name="fio" required />
          <SelectFormik
            label="Роль"
            name="isAdmin"
            data={[
              { value: TypeAdmin.MARKETER, label: 'Маркетолог' },
              { value: TypeAdmin.ADMIN, label: 'Адмін' },
            ]}
          />
          <CustomSelect
            label="Напрями"
            selectedItems={selectedItems}
            setSelectedItems={setSelectedItems}
            data={[
              { value: 1, label: 'Бюджет' },
              { value: 2, label: 'Головбух' },
              { value: 3, label: 'Кадри' },
              { value: 4, label: 'ОТ' },
              { value: 5, label: 'Освіта' },
              { value: 6, label: 'Охорона здоров’я' },
            ]}
          />
          <div style={{ textAlign: 'right' }}>
            <button type="submit" name="editMail" className="btn btn-primary">
              Зберегти
            </button>
          </div>
        </Form>
      )}
    </Formik>
  );
};
