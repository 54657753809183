import { AuthStateType } from './state';
import { ActionAuthType, AUTH_SET_DISABLED, AUTH_SET_ERROR, AUTH_SET_IS_ADMIN } from './actions';

export const preloaderAuth = (state: AuthStateType, action: ActionAuthType) => {
  switch (action.type) {
    case AUTH_SET_DISABLED: {
      return { ...state, disabled: action.isDisabled };
    }
    case AUTH_SET_ERROR: {
      return { ...state, error: action.error };
    }
    case AUTH_SET_IS_ADMIN: {
      return { ...state, isAdmin: action.isAdmin };
    }

    default:
      return state;
  }
};
