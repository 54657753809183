import React from 'react';
import { NavLink } from 'react-router-dom';
import { UsersContext } from '../state/users/provider';
import { ROUTES } from '../utils/routes';
import { UserFrom } from '../Components/UserFrom';

export const CreateUser = () => {
  const { error, createUser } = React.useContext(UsersContext);

  return (
    <div className="container">
      <NavLink to={ROUTES.users} className="btn btn-default pull-right" style={{ marginBottom: '20px' }}>
        Адміністратори
      </NavLink>
      <div className="clearfix" />
      <div className="box">
        <div className="header">
          <h4>Створити користувача</h4>
        </div>
        <div className="content pad">
          <UserFrom onSubmit={createUser} />
          <div>{error ? <span style={{ color: 'red' }}>{error}</span> : null}</div>
        </div>
      </div>
    </div>
  );
};
