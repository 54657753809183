import React from 'react';

export interface Props {
  label?: string;
  data?: { value: string | number; label: string }[];
  selectedItems: number[];
  setSelectedItems: (data: number[]) => void;
}

const CustomSelect: React.FC<Props> = ({ label, data, selectedItems, setSelectedItems }) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const ref = React.useRef<any>(null);

  React.useEffect(() => {
    function handleClickOutside(event: Event) {
      if (ref.current && !ref.current.contains(event.target)) {
        setIsOpen(false);
      }
    }

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [ref]);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleCheckboxChange = (optionId: number) => {
    if (selectedItems.includes(optionId)) {
      setSelectedItems(selectedItems.filter((id) => id !== optionId));
    } else {
      setSelectedItems([...selectedItems, optionId]);
    }
  };

  return (
    <div ref={ref}>
      <div>{label ? <label>{label}</label> : null}</div>
      <div className={`${isOpen ? 'open' : ''} select-checkbox`}>
        <div className="select-box" onClick={toggleDropdown}>
          <div className="selected-items">
            {selectedItems.length > 0 ? (
              selectedItems.map((item) => <span key={item}> {data?.find((option) => option.value === item)?.label},</span>)
            ) : (
              <span>Виберіть</span>
            )}
          </div>
          <div className="arrow"></div>
        </div>
        {isOpen && (
          <ul className="options">
            {data?.map((option) => (
              <li key={option.value}>
                <input
                  type="checkbox"
                  id={`option-${option.value}`}
                  checked={selectedItems.includes(option.value as number)}
                  onChange={() => handleCheckboxChange(option.value as number)}
                />
                <label htmlFor={`option-${option.value}`}>{option.label}</label>
              </li>
            ))}
          </ul>
        )}
      </div>
    </div>
  );
};

export default CustomSelect;
