import React from 'react';
import { NavLink } from 'react-router-dom';
import { AmailersContext } from '../state/amailers/provider';
import { AmailersForm } from '../Components/AmailersForm';
import { AuthContext } from '../state/auth/provider';
import { ROUTES } from '../utils/routes';
import { TypeAdmin } from '../utils/config';

export const Amailers = () => {
  const { isAdmin, logOut } = React.useContext(AuthContext);
  const { amailers, channelsFields, subdivisionFields, setFilter } = React.useContext(AmailersContext);

  if (channelsFields.options?.length === 0) {
    return <div>Loading...</div>;
  }

  return (
    <>
      <nav className="navbar navbar-expand-lg navbar-light bg-light" style={{ paddingLeft: '10px' }}>
        <div className="navbar__block">
          <NavLink to={ROUTES.amailers}>
            <span className="navbar__title" style={{ color: '#000' }}>
              Управління видами розсилок
            </span>
          </NavLink>
          {isAdmin === TypeAdmin.ADMIN ? (
            <NavLink to={ROUTES.users}>
              <span className="navbar__title">Адміністратори</span>
            </NavLink>
          ) : null}
          <button type="submit" className="btn btn-2 navbar__btn" style={{ borderColor: '#ccc' }} onClick={logOut}>
            Вийти
          </button>
        </div>
      </nav>

      <AmailersForm fields={[subdivisionFields, channelsFields]} setFilter={setFilter} />

      <table className="table-hover table-bordered table-sm" style={{ fontSize: '14px' }} id="example">
        <thead>
          <tr>
            <th>Тип розсилок</th>
            <th>Назва продукту</th>
            <th>Заголовок</th>
            <th>Телефон</th>
            <th>Коментар до телефону</th>
            <th>Від кого</th>
            <th>Хто готує ТЗ</th>
            <th>Хто відправляє</th>
            <th>Колір</th>
            <th>Редагувати</th>
          </tr>
          {amailers &&
            amailers.map((item) => (
              <tr key={item.id}>
                <td>{item.id}</td>
                <td>{item.product_name}</td>
                <td align="center">Да</td>
                <td>{item.phone}</td>
                <td>{item.phone_comment}</td>
                <td>{item.mail_from}</td>
                <td>{item.a_cont}</td>
                <td>{item.a_mail}</td>
                <td align="center">{item.mcolor}</td>
                <td align="center">
                  <NavLink to={ROUTES.routeChange(item.id)}>перейти</NavLink>
                </td>
              </tr>
            ))}
        </thead>
      </table>
    </>
  );
};
