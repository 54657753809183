import { AmailersStateType } from './state';
import {
  AMAILERS_SET_AMAILERS,
  AMAILERS_SET_CONFIGURATION,
  AMAILERS_SET_OPTIONS_CHANNEL,
  AMAILERS_SET_OPTIONS_SUBDIVISION,
  AMAILERS_SET_OPTIONS_USERS,
  AMAILERS_SET_VALUE_AMAILERS,
  AMAILERS_SET_VALUE_CHANNEL,
  AMAILERS_SET_VALUE_SUBDIVISION,
  ActionsAmailersType,
} from './actions';
import { AmailersType } from '../../Types/AmailersType';

export const preloaderAmailers = (state: AmailersStateType, action: ActionsAmailersType) => {
  switch (action.type) {
    case AMAILERS_SET_CONFIGURATION: {
      return { ...state, configuration: action.configuration };
    }
    case AMAILERS_SET_AMAILERS: {
      return { ...state, amailers: action.amailers };
    }
    case AMAILERS_SET_VALUE_SUBDIVISION: {
      return { ...state, subdivisionFields: { ...state.subdivisionFields, value: action.value } };
    }
    case AMAILERS_SET_VALUE_CHANNEL: {
      return { ...state, channelsFields: { ...state.channelsFields, value: action.value } };
    }
    case AMAILERS_SET_VALUE_AMAILERS: {
      return {
        ...state,
        amailersFields: state.amailersFields.map((item) => {
          state.amailers.forEach((amailer) => {
            if (amailer.id === action.id) {
              item.value = amailer[item.name as keyof AmailersType] || '';
            }
          });
          return { ...item };
        }),
      };
    }
    case AMAILERS_SET_OPTIONS_SUBDIVISION: {
      return { ...state, subdivisionFields: { ...state.subdivisionFields, options: action.options } };
    }
    case AMAILERS_SET_OPTIONS_CHANNEL: {
      return { ...state, channelsFields: { ...state.channelsFields, options: [state.channelsFields.options[0], ...action.options] } };
    }
    case AMAILERS_SET_OPTIONS_USERS: {
      return {
        ...state,
        amailersFields: state.amailersFields.map((item) => {
          if (item.name !== 'header' && item.options) {
            const users = action.options.filter((item) => item.position === action.subdivision);
            item.options = [item.options[0], ...users];
          }
          return { ...item };
        }),
      };
    }

    default:
      return state;
  }
};
