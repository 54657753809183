import React from 'react';
import { Amailers } from '../../Request/amailers';
import { InitialAmailersType, UpdatedAmailersType } from '../../Types/AmailersType';
import { useNavigate } from 'react-router-dom';
import { preloaderAmailers } from './reducer';
import { amailersState } from './state';
import { actionAmailers } from './actions';
import { ROUTES } from '../../utils/routes';
import { AuthContext } from '../auth/provider';
import { TypeAdmin } from '../../utils/config';

export const AmailersContext = React.createContext<InitialAmailersType>({} as InitialAmailersType);

interface AmailersProviderProps {
  children: any;
}

export const AmailersProvider = ({ children }: AmailersProviderProps) => {
  const [state, dispatch] = React.useReducer(preloaderAmailers, amailersState);
  const navigate = useNavigate();

  const { isAdmin } = React.useContext(AuthContext);

  const setValue = React.useCallback((id: string) => {
    dispatch(actionAmailers.setValueAmailers(id));
  }, []);

  const getAmailers = async (subdivision: string, channel: string) => {
    try {
      const data = await Amailers.getAmailers(subdivision, channel);
      dispatch(actionAmailers.setAmailers(data));
    } catch (e: any) {
      console.log(e);
    }
  };

  const getConfiguration = React.useCallback(async () => {
    try {
      const data = await Amailers.getConfiguration();
      if (data.subdivision[0]) {
        getAmailers(String(data.subdivision[0].id), String(data.channels[0].id));
        dispatch(actionAmailers.setConfiguration(data));
        dispatch(actionAmailers.setValueSubdivision(String(data.subdivision[0].id)));
        dispatch(actionAmailers.setOptionsSubdivision(data.subdivision));
        dispatch(actionAmailers.setOptionsChannel(data.channels));
        dispatch(actionAmailers.setOptionsUsers(data.users, data.subdivision[0].id));
      }
    } catch (e: any) {
      console.log(e);
    }
  }, []);

  const updateAmailers = React.useCallback(
    async (amailer: UpdatedAmailersType, id: string) => {
      try {
        //@ts-ignore
        delete amailer.header;
        const obj = Object.entries(amailer).reduce((acc, item) => {
          if (item[1]) {
            acc[item[0]] = item[1];
          }
          if (item[0] === 'a_cont' || item[0] === 'a_mail') {
            acc[item[0]] = Number(item[1]);
          }
          return acc;
        }, {} as { [key: string]: string | number });
        await Amailers.updateAmailers(id, obj as UpdatedAmailersType);
        await getAmailers(state.subdivisionFields.value, state.channelsFields.value);
        navigate(ROUTES.amailers);
      } catch (e: any) {
        console.log(e);
      }
    },
    [navigate, state]
  );

  const setFilter = React.useCallback((subdivision: string, channel: string) => {
    getAmailers(subdivision, channel);
    dispatch(actionAmailers.setValueSubdivision(subdivision));
    dispatch(actionAmailers.setValueChannel(channel));
  }, []);

  React.useEffect(() => {
    if ([TypeAdmin.ADMIN, TypeAdmin.MARKETER].includes(isAdmin)) {
      getConfiguration();
    }
  }, [getConfiguration, isAdmin]);

  const value = {
    configuration: state.configuration,
    amailers: state.amailers,
    channelsFields: state.channelsFields,
    subdivisionFields: state.subdivisionFields,
    amailersFields: state.amailersFields,
    updateAmailers,
    setFilter,
    setValue,
  };

  return <AmailersContext.Provider value={value}>{children}</AmailersContext.Provider>;
};
