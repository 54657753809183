import React from 'react';
import { useParams } from 'react-router-dom';
import { NavLink } from 'react-router-dom';
import { UsersContext } from '../state/users/provider';
import { UserType } from '../Types/UsersType';
import { ROUTES } from '../utils/routes';
import { UserFrom } from '../Components/UserFrom';

export const UpdateUser = () => {
  const [user, setUser] = React.useState<UserType | undefined>();
  const { error, users, updateUser } = React.useContext(UsersContext);
  const { id } = useParams<{ id: string }>();

  React.useEffect(() => {
    if (id) {
      const item = users.find((item) => item.id === Number(id));
      setUser(item);
    }
  }, [users, id]);

  return (
    <div className="container">
      <NavLink to={ROUTES.users} className="btn btn-default pull-right" style={{ marginBottom: '20px' }}>
        Адміністратори
      </NavLink>
      <div className="clearfix" />
      <div className="box">
        <div className="header">
          <h4>
            Редегувати користувача: №{user?.id || ''} {user?.fio || ''}
          </h4>
        </div>
        <div className="content pad">
          <UserFrom data={user} onSubmit={(data) => (user ? updateUser(data, user.id) : null)} />
          <div>{error ? <span style={{ color: 'red' }}>{error}</span> : null}</div>
        </div>
      </div>
    </div>
  );
};
