import { UsersStateType } from './state';
import { ActionType, USERS_DELETE_USER, USERS_SET_DISABLED, USERS_SET_ERROR, USERS_SET_USER, USERS_SET_USERS, USERS_UPDATE_USER } from './actions';

export const reducer = (state: UsersStateType, action: ActionType) => {
  switch (action.type) {
    case USERS_SET_DISABLED: {
      return { ...state, disabled: action.isDisabled };
    }
    case USERS_SET_ERROR: {
      return { ...state, error: action.error };
    }
    case USERS_SET_USERS: {
      return { ...state, users: action.users };
    }
    case USERS_SET_USER: {
      return { ...state, users: [...state.users, action.user] };
    }
    case USERS_UPDATE_USER: {
      return { ...state, users: state.users.map((item) => (item.id === action.user.id ? action.user : item)) };
    }
    case USERS_DELETE_USER: {
      return { ...state, users: state.users.filter((item) => item.id !== action.user.id) };
    }

    default:
      return state;
  }
};
