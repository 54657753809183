import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { NavLink } from 'react-router-dom';
import { UsersContext } from '../state/users/provider';
import { UserType } from '../Types/UsersType';
import { ROUTES } from '../utils/routes';

export const DeleteUser = () => {
  const [user, setUser] = React.useState<UserType | undefined>();
  const { error, users, deleteUser } = React.useContext(UsersContext);
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();

  React.useEffect(() => {
    if (id) {
      const item = users.find((item) => item.id === Number(id));
      setUser(item);
    }
  }, [users, id]);

  const handleApprove = () => {
    if (user) {
      deleteUser(user.id);
    }
  };

  const handleCancle = () => {
    navigate(ROUTES.users);
  };

  return (
    <div className="container">
      <NavLink to={ROUTES.users} className="btn btn-default pull-right" style={{ marginBottom: '20px' }}>
        Адміністратори
      </NavLink>
      <div className="clearfix" />
      <div className="box">
        <div className="header">
          <h4>Видалення</h4>
        </div>
        <div className="content pad">
          <h4 style={{ textAlign: 'center' }}>
            Видалити користувача: №{user?.id || ''} {user?.fio || ''}
          </h4>
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <button className="btn btn-primary" onClick={handleApprove}>
              Так
            </button>
            <button className="btn btn-2 btn-default" onClick={handleCancle}>
              Ні
            </button>
          </div>
          <div>{error ? <span style={{ color: 'red' }}>{error}</span> : null}</div>
        </div>
      </div>
    </div>
  );
};
