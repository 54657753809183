import React from 'react';
import { Form, Formik } from 'formik';
import { Select, TextInput } from '../ui/TextInput';
import { FormItemType } from '../../Types/AuthType';

type PropsType = {
  fields: FormItemType[];
  onSubmit: (data: any) => void;
};

export const EditFrom = ({ fields, onSubmit }: PropsType) => {
  const object = fields.reduce((acm, item) => {
    acm[item.name] = item.value;
    return acm;
  }, {} as { [x: string]: string | number });

  return (
    <Formik enableReinitialize={true} initialValues={object} onSubmit={onSubmit}>
      {() => (
        <Form id="upgrade_info">
          {fields.map((item) => {
            if (item.type === 'select') {
              return (
                <span key={item.id}>
                  <Select key={item.id} item={item}>
                    {item.options &&
                      item.options.map((option) => (
                        <option key={option.id} value={option.id}>
                          {option.name}
                        </option>
                      ))}
                  </Select>
                  <br />
                </span>
              );
            }

            return (
              <span key={item.id}>
                <TextInput item={item} />
                <br />
              </span>
            );
          })}
          <div style={{ textAlign: 'right' }}>
            <button type="submit" name="editMail" className="btn btn-primary">
              Зберегти
            </button>
          </div>
        </Form>
      )}
    </Formik>
  );
};
