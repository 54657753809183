import React from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import { UsersContext } from '../state/users/provider';
import { ROUTES } from '../utils/routes';
import { AuthContext } from '../state/auth/provider';
import { TypeAdmin } from '../utils/config';

export const UserList = () => {
  const { isAdmin, logOut } = React.useContext(AuthContext);
  const { users } = React.useContext(UsersContext);
  const navigate = useNavigate();

  return (
    <>
      <nav className="navbar navbar-expand-lg navbar-light bg-light" style={{ paddingLeft: '10px' }}>
        <div className="navbar__block">
          <NavLink to={ROUTES.amailers}>
            <span className="navbar__title">Управління видами розсилок</span>
          </NavLink>
          {isAdmin === TypeAdmin.ADMIN ? (
            <NavLink to={ROUTES.users}>
              <span className="navbar__title" style={{ color: '#000' }}>
                Адміністратори
              </span>
            </NavLink>
          ) : null}
          <button type="submit" className="btn btn-2 navbar__btn" style={{ borderColor: '#ccc' }} onClick={logOut}>
            Вийти
          </button>
        </div>
      </nav>
      <div>
        <button className="btn btn-2 btn-default" onClick={() => navigate(ROUTES.routeChange('new'))}>
          Створити
        </button>
      </div>
      <table className="table-hover table-bordered table-sm" style={{ fontSize: '14px' }} id="example">
        <thead>
          <tr>
            <th>ID</th>
            <th>ПІБ</th>
            <th>Е-пошта</th>
            <th>Роль</th>
            <th>Редагувати</th>
            <th>Видалити</th>
          </tr>
          {users.map((item) => (
            <tr key={item.id}>
              <td>{item.id}</td>
              <td>{item.fio}</td>
              <td>{item.username}</td>
              <td>{item.isAdmin === TypeAdmin.ADMIN ? 'Адмін' : 'Маркетолог'}</td>
              <td align="center">
                <NavLink to={ROUTES.routeChange(item.id)}>перейти</NavLink>
              </td>
              <td align="center">
                <NavLink to={`${ROUTES.delete}/${item.id}`}>перейти</NavLink>
              </td>
            </tr>
          ))}
        </thead>
      </table>
    </>
  );
};
