import React from 'react';
import { CircularProgress, CssBaseline, Container, Avatar, Typography, Box, Alert, Button, AlertTitle } from '@mui/material';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import { makeStyles } from '@mui/styles';
import { Copyright } from '../Components/Copyright';
import { Field, Form, Formik } from 'formik';
import { TextField } from 'formik-mui';
import { AuthContext } from '../state/auth/provider';

const useStyles = makeStyles(() => ({
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: 10,
  },
}));

interface Values {
  email: string;
  password: string;
}

export const Login = () => {
  const { error, loginIn } = React.useContext(AuthContext);
  const classes = useStyles();

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <Box
        sx={{
          marginTop: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Avatar sx={{ m: 1, bgcolor: 'error.main' }}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Увійти
        </Typography>
        <Formik
          initialValues={{
            email: '',
            password: '',
          }}
          validate={(values) => {
            const errors: Partial<Values> = {};
            if (!values.email) {
              errors.email = `Обов'зкове поле`;
            }
            if (!values.password) {
              errors.password = `Обов'зкове поле`;
            }

            return errors;
          }}
          onSubmit={(values, { setSubmitting }) => {
            loginIn(values, () => setSubmitting(false));
          }}
        >
          {({ submitForm, isSubmitting }) => (
            <Form className={classes.form}>
              <Field
                component={TextField}
                variant="outlined"
                margin="normal"
                // required
                fullWidth
                label="Ваш Email"
                name="email"
                autoComplete="email"
                autoFocus
              />
              <Field
                component={TextField}
                variant="outlined"
                margin="normal"
                // required
                fullWidth
                name="password"
                label="Пароль"
                type="password"
                id="password"
                autoComplete="current-password"
              />

              <Button
                // type="submit"
                fullWidth
                variant="contained"
                color="primary"
                sx={{ mt: 3, mb: 2 }}
                disabled={isSubmitting}
                onClick={submitForm}
                style={{ height: '36px' }}
              >
                {isSubmitting ? <CircularProgress size={15} /> : 'Увiйти'}
              </Button>
            </Form>
          )}
        </Formik>
        {error ? (
          <Alert severity="error" style={{ width: '100%' }}>
            <AlertTitle>Помилка</AlertTitle>
            {error}
          </Alert>
        ) : null}
      </Box>
      <Box mt={3}>
        <Copyright />
      </Box>
    </Container>
  );
};
