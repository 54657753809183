import React from 'react';
import jwt_decode from 'jwt-decode';
import { authState } from './state';
import { preloaderAuth } from './reducer';
import { actionAuth } from './actions';
import { Auth } from '../../Request/auth';
import { AuthType, InitialAuthType } from '../../Types/AuthType';
import { useNavigate } from 'react-router-dom';
import { TOKEN, TypeAdmin } from '../../utils/config';
import { checkToken } from '../../utils/checkToken';
import { ROUTES } from '../../utils/routes';
import { UserType } from '../../Types/UsersType';

export const AuthContext = React.createContext<InitialAuthType>({} as InitialAuthType);

interface AuthProviderProps {
  children: React.ReactElement<any, any>;
}

export const AuthProvider = ({ children }: AuthProviderProps) => {
  const [state, dispatch] = React.useReducer(preloaderAuth, authState);
  const navigate = useNavigate();

  React.useEffect(() => {
    checkToken()
      .then((data) => {
        if (!data.success) {
          navigate(ROUTES.login);
        }
        dispatch(actionAuth.setIsAdmin(data.isAdmin || 0));
      })
      .catch((err) => {
        console.log(err);
        navigate(ROUTES.login);
      });
  }, [navigate]);

  const loginIn = React.useCallback(
    async (form: AuthType, callback: () => void) => {
      try {
        dispatch(actionAuth.setError(null));
        dispatch(actionAuth.setDisabled(true));
        const data = await Auth.postLogin(form);
        const decodeToken = jwt_decode<UserType>(data.refreshToken || '');
        if ([TypeAdmin.ADMIN, TypeAdmin.MARKETER].includes(decodeToken.isAdmin)) {
          localStorage.setItem(TOKEN.access, data.accessToken);
          localStorage.setItem(TOKEN.refresh, data.refreshToken);
          dispatch(actionAuth.setIsAdmin(decodeToken.isAdmin || 0));
          navigate(ROUTES.amailers);
        } else {
          dispatch(actionAuth.setError('Доступ заборонено'));
        }
        dispatch(actionAuth.setDisabled(false));
      } catch (e: any) {
        if (e.response && e.response.data) {
          dispatch(actionAuth.setError(e.response.data.message));
        }
        dispatch(actionAuth.setDisabled(false));
      } finally {
        callback();
      }
    },
    [navigate]
  );

  const logOut = React.useCallback(() => {
    localStorage.removeItem(TOKEN.access);
    localStorage.removeItem(TOKEN.refresh);
    navigate(ROUTES.login);
  }, [navigate]);

  const value = {
    ...state,
    loginIn,
    logOut,
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};
