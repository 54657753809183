import { InferActionsTypes } from '../../Types/InferActionsType';

export const AUTH_SET_DISABLED = 'AUTH_SET_DISABLED';
export const AUTH_SET_ERROR = 'AUTH_SET_ERROR';
export const AUTH_SET_IS_ADMIN = 'AUTH_SET_IS_ADMIN';

export const actionAuth = {
  setDisabled: (isDisabled: boolean) => ({ type: AUTH_SET_DISABLED, isDisabled } as const),
  setError: (error: string | null) => ({ type: AUTH_SET_ERROR, error } as const),
  setIsAdmin: (isAdmin: number) => ({ type: AUTH_SET_IS_ADMIN, isAdmin } as const),
};

export type ActionAuthType = InferActionsTypes<typeof actionAuth>;
