import { AmailersType, ConfigurationType, SubdivisionType } from '../../Types/AmailersType';

export const amailersState = {
  configuration: {} as unknown as ConfigurationType,
  amailers: [] as AmailersType[],
  amailersFields: [
    {
      id: 'header',
      type: 'select',
      name: 'header',
      placeholder: '',
      value: '',
      label: 'Розсилка із заголовком:',
      require: false,
      min: null,
      max: null,
      autoComplete: 'off',
      options: [
        { id: 0, name: 'Да', position: 0 },
        { id: 1, name: 'Нет', position: 1 },
      ],
    },
    {
      id: 'logo_img',
      type: 'text',
      name: 'logo_img',
      value: '',
      label: 'Адреса логотипу:',
      require: false,
      min: null,
      max: null,
      autoComplete: 'off',
      placeholder: 'logo_img',
    },
    {
      id: 'logo_url',
      type: 'text',
      name: 'logo_url',
      placeholder: 'logo_url',
      value: '',
      label: 'Посилання з логотипу:',
      require: false,
      min: null,
      max: null,
      autoComplete: 'off',
    },
    {
      id: 'logo_alt',
      type: 'text',
      name: 'logo_alt',
      placeholder: 'logo_alt',
      value: '',
      label: 'ALT для логотипу',
      require: false,
      min: null,
      max: null,
      autoComplete: 'off',
    },
    {
      id: 'text_anwser',
      type: 'text',
      name: 'text_anwser',
      placeholder: 'text_anwser',
      value: '',
      label: 'Текст',
      require: false,
      min: null,
      max: null,
      autoComplete: 'off',
    },
    {
      id: 'phone',
      type: 'text',
      name: 'phone',
      placeholder: 'phone',
      value: '',
      label: 'Телефон:',
      require: false,
      min: null,
      max: null,
      autoComplete: 'off',
    },
    {
      id: 'phone_comment',
      type: 'text',
      name: 'phone_comment',
      placeholder: 'phone_comment',
      value: '',
      label: 'Текст під телефоном:',
      require: false,
      min: null,
      max: null,
      autoComplete: 'off',
    },
    {
      id: 'footer_link',
      type: 'text',
      name: 'footer_link',
      placeholder: 'footer_link',
      value: '',
      label: 'Посилання у футері:',
      require: false,
      min: null,
      max: null,
      autoComplete: 'off',
    },
    {
      id: 'mcolor',
      type: 'text',
      name: 'mcolor',
      placeholder: 'mcolor',
      value: '',
      label: 'Колір цього виду розсилки:',
      require: false,
      min: null,
      max: null,
      autoComplete: 'off',
    },
    {
      id: 'segment_plus',
      type: 'text',
      name: 'segment_plus',
      placeholder: 'segment_plus',
      value: '',
      label: 'Сегмент умова:',
      require: false,
      min: null,
      max: null,
      autoComplete: 'off',
    },
    {
      id: 'group_ofsys',
      type: 'text',
      name: 'group_ofsys',
      placeholder: 'group_ofsys',
      value: '',
      label: 'Посилання на сегмент:',
      require: false,
      min: null,
      max: null,
      autoComplete: 'off',
    },
    {
      id: 'mail_from',
      type: 'text',
      name: 'mail_from',
      placeholder: 'mail_from',
      value: '',
      label: 'Від кого:',
      require: false,
      min: null,
      max: null,
      autoComplete: 'off',
    },
    {
      id: 'a_cont',
      type: 'select',
      name: 'a_cont',
      placeholder: 'a_cont',
      value: 0,
      label: 'Хто готує ТЗ:',
      require: false,
      min: null,
      max: null,
      autoComplete: 'off',
      options: [{ id: 0, name: 'Виберіть зі списку', position: 0 }],
    },
    {
      id: 'a_mail',
      type: 'select',
      name: 'a_mail',
      placeholder: 'a_mail',
      value: 0,
      label: 'Хто відправляє:',
      require: false,
      min: null,
      max: null,
      autoComplete: 'off',
      options: [{ id: 0, name: 'Виберіть зі списку', position: 0 }],
    },
  ],
  subdivisionFields: {
    id: 'cat_id',
    type: 'text',
    name: 'cat_id',
    value: '',
    label: 'Напрям',
    require: false,
    min: null,
    max: null,
    autoComplete: 'off',
    placeholder: '',
    options: [] as SubdivisionType[],
  },
  channelsFields: {
    id: 'channel_id',
    type: 'text',
    name: 'channel_id',
    value: '',
    label: 'Канал',
    require: false,
    min: null,
    max: null,
    autoComplete: 'off',
    placeholder: '',
    options: [{ id: 0, name: 'ALL', position: 0 }],
  },
};

export type AmailersStateType = typeof amailersState;
