import React from 'react';
import { useParams } from 'react-router-dom';
import { NavLink } from 'react-router-dom';
import { AmailersContext } from '../state/amailers/provider';
import { EditFrom } from '../Components/EditFrom';
import { AmailersType } from '../Types/AmailersType';
import { ROUTES } from '../utils/routes';

export const AmailersEdit = () => {
  const [amailer, setAmailer] = React.useState<AmailersType | undefined>();
  const { amailers, amailersFields, updateAmailers, setValue } = React.useContext(AmailersContext);
  const { id } = useParams<{ id: string }>();

  React.useEffect(() => {
    if (id) {
      const amailer = amailers && amailers.find((item) => item.id === id);
      setAmailer(amailer);
      setValue(id);
    }
  }, [amailers, setValue, id]);

  return (
    <div className="container">
      <NavLink to={ROUTES.amailers} className="btn btn-default pull-right" style={{ marginBottom: '20px' }}>
        Всі види розсилок
      </NavLink>
      <div className="clearfix" />
      <div className="box">
        <div className="header">
          <h4>
            Управління видом розсилки: {amailer?.id || ''}, продукт: {amailer?.product_name || ''}
          </h4>
        </div>
        <div className="content pad">
          <EditFrom fields={amailersFields} onSubmit={(data) => (amailer ? updateAmailers(data, amailer.id) : null)} />
        </div>
      </div>
    </div>
  );
};
