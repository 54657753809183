export const authState = {
  email: {
    id: 'email',
    type: 'email',
    name: 'email',
    value: '',
    label: 'Ваш Email',
    require: true,
    min: null,
    max: null,
    autoComplete: 'email',
    placeholder: 'Ваш Email',
  },
  password: {
    id: 'password',
    type: 'password',
    name: 'password',
    value: '',
    label: 'Пароль',
    require: true,
    min: null,
    max: null,
    autoComplete: 'current-password',
    placeholder: 'Пароль',
  },
  disabled: false,
  error: null as null | string,
  isAdmin: 0,
};
export type AuthStateType = typeof authState;
