import React from 'react';
import { FormikValues, useField } from 'formik';
import { FieldMetaProps } from 'formik/dist/types';
import { FormItemType } from '../../Types/AuthType';

export const onValidate = (value: FormikValues, item: { require?: boolean; min?: number | null; max?: number | null }) => {
  if (item.require && !value) {
    return `Обов'язкове поле`;
  }

  if (item.min) {
    if (value.length < item.min) {
      return `Invalid ${item.min}`;
    }
  }

  if (item.max) {
    if (value.length > item.max) {
      return `Invalid ${item.max}`;
    }
  }
};

type MySelectType = {
  item: FormItemType;
  children: JSX.Element[] | undefined;
};

type MyTextInputType = {
  item: FormItemType;
};

type ControlFieldType = {
  item: FormItemType;
  meta: FieldMetaProps<any>;
  children: JSX.Element;
};

export const Select = React.memo(({ item, children }: MySelectType) => {
  const [field, meta] = useField({ name: item.name, validate: (value: FormikValues) => onValidate(value, item) });
  return (
    <ControlField item={item} meta={meta}>
      <select className="form-control" {...field}>
        {children}
      </select>
    </ControlField>
  );
});

export const TextInput = React.memo(({ item }: MyTextInputType) => {
  const [field, meta] = useField({ name: item.name, validate: (value: FormikValues) => onValidate(value, item) });
  return (
    <ControlField item={item} meta={meta}>
      <input className="form-control" type={item.type} placeholder={item.placeholder} autoComplete={item.autoComplete} {...field} />
    </ControlField>
  );
});

const ControlField = React.memo<ControlFieldType>(({ item, children, meta }) => {
  return (
    <>
      {item.label && <label htmlFor={item.name}>{item.label}</label>}
      {children}
      {meta.error && meta.touched ? <span style={{ color: 'red' }}>{meta.error}</span> : null}
    </>
  );
});
