import React from 'react';
import { Form, Formik } from 'formik';
import { Select } from '../ui/TextInput';
import { FormItemType } from '../../Types/AuthType';

type PropsType = {
  fields: FormItemType[];
  setFilter: (subdivision: string, channel: string) => void;
};

export const AmailersForm = React.memo(({ fields, setFilter }: PropsType) => {
  const object = fields.reduce((acm, item) => {
    // @ts-ignore
    acm[item.name] = item.value !== null ? item.value : undefined;
    return acm;
  }, {} as { channel_id: string; cat_id: string });
  return (
    <Formik initialValues={object} onSubmit={(values) => setFilter(values.cat_id, values.channel_id)} enableReinitialize={true}>
      {() => (
        <Form className="form-inline" style={{ paddingTop: '10px' }}>
          {fields.map((field, index) => (
            <div className="form-group" key={index}>
              <Select item={field}>
                {field.options?.map((item) => (
                  <option key={item.id} value={item.id}>
                    {item.name}
                  </option>
                ))}
              </Select>
            </div>
          ))}
          <button type="submit" className="btn btn-2 btn-default" style={{ margin: '10px' }}>
            Вибрати
          </button>
        </Form>
      )}
    </Formik>
  );
});
