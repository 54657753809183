import { InferActionsTypes } from '../../Types/InferActionsType';
import { UserType } from '../../Types/UsersType';

export const USERS_SET_DISABLED = 'USERS_SET_DISABLED';
export const USERS_SET_ERROR = 'USERS_SET_ERROR';
export const USERS_SET_USERS = 'USERS_SET_USERS';
export const USERS_SET_USER = 'USERS_SET_USER';
export const USERS_UPDATE_USER = 'USERS_UPDATE_USER';
export const USERS_DELETE_USER = 'USERS_DELETE_USER';

export const actionUsers = {
  setDisabled: (isDisabled: boolean) => ({ type: USERS_SET_DISABLED, isDisabled } as const),
  setError: (error: string | null) => ({ type: USERS_SET_ERROR, error } as const),
  setUsers: (users: UserType[]) => ({ type: USERS_SET_USERS, users } as const),
  setUser: (user: UserType) => ({ type: USERS_SET_USER, user } as const),
  updateUser: (user: UserType) => ({ type: USERS_UPDATE_USER, user } as const),
  deleteUser: (user: UserType) => ({ type: USERS_DELETE_USER, user } as const),
};

export type ActionType = InferActionsTypes<typeof actionUsers>;
