import { InferActionsTypes } from '../../Types/InferActionsType';
import { AmailersType, ChannelsType, ConfigurationType, SubdivisionType, Users } from '../../Types/AmailersType';

export const AMAILERS_SET_CONFIGURATION = 'AMAILERS_SET_CONFIGURATION';
export const AMAILERS_SET_AMAILERS = 'AMAILERS_SET_AMAILERS';
export const AMAILERS_SET_VALUE_SUBDIVISION = 'AMAILERS_SET_VALUE_SUBDIVISION';
export const AMAILERS_SET_VALUE_CHANNEL = 'AMAILERS_SET_VALUE_CHANNEL';
export const AMAILERS_SET_VALUE_AMAILERS = 'AMAILERS_SET_VALUE_AMAILERS';
export const AMAILERS_SET_OPTIONS_SUBDIVISION = 'AMAILERS_SET_OPTIONS_SUBDIVISION';
export const AMAILERS_SET_OPTIONS_CHANNEL = 'AMAILERS_SET_OPTIONS_CHANNEL';
export const AMAILERS_SET_OPTIONS_USERS = 'AMAILERS_SET_OPTIONS_USERS';

export const actionAmailers = {
  setConfiguration: (configuration: ConfigurationType) => ({ type: AMAILERS_SET_CONFIGURATION, configuration } as const),
  setAmailers: (amailers: AmailersType[]) => ({ type: AMAILERS_SET_AMAILERS, amailers } as const),
  setValueSubdivision: (value: string) => ({ type: AMAILERS_SET_VALUE_SUBDIVISION, value } as const),
  setValueChannel: (value: string) => ({ type: AMAILERS_SET_VALUE_CHANNEL, value } as const),
  setValueAmailers: (id: string) => ({ type: AMAILERS_SET_VALUE_AMAILERS, id } as const),
  setOptionsSubdivision: (options: SubdivisionType[]) => ({ type: AMAILERS_SET_OPTIONS_SUBDIVISION, options } as const),
  setOptionsChannel: (options: ChannelsType[]) => ({ type: AMAILERS_SET_OPTIONS_CHANNEL, options } as const),
  setOptionsUsers: (options: Users[], subdivision: number) => ({ type: AMAILERS_SET_OPTIONS_USERS, options, subdivision } as const),
};

export type ActionsAmailersType = InferActionsTypes<typeof actionAmailers>;
