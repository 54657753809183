import { instance } from './istance';
import { UpdateUserType, UserType } from '../Types/UsersType';
import { TOKEN } from '../utils/config';

export class Users {
  static get token() {
    return localStorage.getItem(TOKEN.access);
  }

  static async getUsers() {
    return instance
      .get<UserType[]>(`api/users`, {
        headers: {
          Authorization: this.token,
        },
      })
      .then((res) => res.data);
  }

  static async createUser(data: UpdateUserType) {
    return instance
      .post<UserType>(`api/users`, data, {
        headers: {
          Authorization: this.token,
        },
      })
      .then((res) => res.data);
  }

  static async updateUser(id: number, data: UpdateUserType) {
    return instance
      .put<UserType>(`api/users/${id}`, data, {
        headers: {
          Authorization: this.token,
        },
      })
      .then((res) => res.data);
  }

  static async deleteUser(id: number) {
    return instance
      .delete<UserType>(`api/users/${id}`, {
        headers: {
          Authorization: this.token,
        },
      })
      .then((res) => res.data);
  }
}
